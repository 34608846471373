
<template>
  <div>
    <div
      data-collapse="medium"
      data-animation="default"
      data-duration="400"
      data-easing="ease"
      data-easing2="ease"
      role="banner"
      class="navigation w-nav"
    >
      <div class="navigation-wrap">
        <a
          href="/"
          aria-current="page"
          class="logo-link w-nav-brand w--current"
          ><img src="../../assets/images/logo.svg" width="108" alt="" class="logo-image"
        /></a>
        <div class="menu">
          <nav role="navigation" class="navigation-items w-nav-menu">
            <a href="/about" class="navigation-item w-nav-link" style="color: #000000 !important;  "
              >Descripción de la Aplicación</a
            >
            <a href="#" class="navigation-item w-nav-link" style="color: #000000 !important; "
             @click="changeVideo"
            
              >Video Explicativo</a
            >
            <a @click="changeDrawer" class="navigation-item w-nav-link" style="color: #000000 !important ">Contacto</a>
          </nav>
          <div class="menu-button w-nav-button">
            <img
              src="../../assets/images/menu-icon_1menu-icon.png"
              width="22"
              alt=""
              class="menu-icon"
            />
          </div>
        </div>
        <a
          href="mailto:mail@business.com?subject=You&#x27;ve%20got%20mail!"
          class="button cc-contact-us w-inline-block"
        >
          <div>Descargar el app</div>
        </a>
      </div>
    </div>
    <div class="section cc-store-home-wrap">
      <div class="intro-header">
        <div class="intro-content cc-homepage">
          <div class="div-block-2">
            <div class="heading-jumbo">SOSCLICK MUJER</div>
            <div class="paragraph-bigger cc-bigger-white-light">
              Es una Aplicación Móvil con la más avanzada tecnología, la cual te
              posibilitará ante cualquier acto de acoso sexual que sufras, no
              solo grabar un video de tu acosador, sino que con la sola
              activación de la denuncia, también se podrán comunicar contigo por
              videollamada tu red de seguridad personal.<br />
            </div>
            <a
              href="/about"
              class="button cc-jumbo-button cc-jumbo-white w-inline-block"
            >
              <div class="text-block">Descarga app</div>
            </a>
          </div>
          <div>
            <div class="div-block"
             @click="changeVideo"
              style="cursor: pointer"
            ></div>

            <!-- <div -->
            <!-- > -->

            <!-- <iframe
                class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FRW_EclTXHxk%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DRW_EclTXHxk&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FRW_EclTXHxk%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true">
                  
              ></iframe> -->
            <!-- </div> -->
            <div
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 99999 !important;
              "
              v-if="video"
              @click="changeVideo"

            >
              <div
                class="w-lightbox-backdrop"
                style="transition: opacity 300ms ease 0s; opacity: 1"
              >
                <div class="w-lightbox-container">
                  <div class="w-lightbox-content">
                    <div
                      class="w-lightbox-view"
                      tabindex="0"
                      id="w-lightbox-view"
                      style="opacity: 1"
                    >
                      <div class="w-lightbox-frame">
                        <figure class="w-lightbox-figure">
                          <v-progress-circular
                            :size="50"
                            :width="7"
                            color="white"
                            indeterminate
                            style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"
                          ></v-progress-circular>
                          <img
                            class="w-lightbox-img w-lightbox-image"
                            src="data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20width=%22940%22%20height=%22528%22/%3E"
                          />
                          <iframe
                            class="embedly-embed w-lightbox-embed"
                            src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FtxjGqv-GUxg%3Ffeature%3Doembed&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DtxjGqv-GUxg%26rel%3D0%26enablejsapi%3D1&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FRW_EclTXHxk%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube"
                            width="940"
                            height="528"
                            scrolling="no"
                            title="YouTube embed"
                            frameborder="0"
                            allow="autoplay; fullscreen"
                            allowfullscreen="true"
                          >

                          </iframe>
                        </figure>
                      </div>
                    </div>
                    <div
                      class="w-lightbox-spinner w-lightbox-hide"
                      role="progressbar"
                      aria-live="polite"
                      aria-hidden="true"
                      aria-busy="false"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      aria-valuenow="100"
                      aria-valuetext="Loaded image"
                    ></div>
                    <div
                      class="
                        w-lightbox-control w-lightbox-left w-lightbox-inactive
                      "
                      role="button"
                      aria-hidden="true"
                      aria-controls="w-lightbox-view"
                      aria-label="previous image"
                      tabindex="-1"
                    ></div>
                    <div
                      class="
                        w-lightbox-control w-lightbox-right w-lightbox-inactive
                      "
                      role="button"
                      aria-hidden="true"
                      aria-controls="w-lightbox-view"
                      aria-label="next image"
                      tabindex="-1"
                    ></div>
                    <div
                      class="w-lightbox-control w-lightbox-close"
                      role="button"
                      aria-label="close lightbox"
                      tabindex="0"
                      @click="changeVideo"
                    ></div>
                  </div>
                  <div class="w-lightbox-strip" role="tablist"></div>
                </div>
              </div>
            </div>

            <!-- <script type="application/json" class="w-json">
              {
                "items": [
                  {
                    "url": "https://www.youtube.com/watch?v=RW_EclTXHxk",
                    "originalUrl": "https://www.youtube.com/watch?v=RW_EclTXHxk",
                    "width": 940,
                    "height": 528,
                    "thumbnailUrl": "https://i.ytimg.com/vi/RW_EclTXHxk/hqdefault.jpg",
                    "html": "<iframe class=\"embedly-embed\" src=\"//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FRW_EclTXHxk%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DRW_EclTXHxk&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FRW_EclTXHxk%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube\" width=\"940\" height=\"528\" scrolling=\"no\" title=\"YouTube embed\" frameborder=\"0\" allow=\"autoplay; fullscreen\" allowfullscreen=\"true\"></iframe>",
                    "type": "video"
                  }
                ],
                "group": ""
              }
            </script> -->
          </div>
        </div>
      </div>
    </div>
    <div class="section-2 wf-section">
      <div class="div-block-3">
        <h1 class="heading">El Acoso Sexual</h1>
        <p class="paragraph" style="text-align: justify">
          El acoso sexual es una conducta no deseada de naturaleza sexual en
          cualquier lugar e incluso en el trabajo, que hace que la persona se
          sienta ofendida, humillada y/o intimidada. <br />
          “En definitiva es el comportamiento en función del sexo, de carácter
          desagradable y ofensivo para la persona que lo sufre. Para que se
          trate de acoso sexual es necesaria la confluencia de ambos aspectos
          negativos: no deseado y ofensivo”. El acoso es un comportamiento que
          puede desarrollar una persona contra otra y que se caracteriza por el
          reiterado hostigamiento y persecución y que tiene la misión y el
          objetivo, de lograr que la otra persona acceda a hacer aquello que se
          le exige insistentemente. Siempre en cualquiera de sus formas, el
          acoso, generará incomodidad en la otra persona. Situación frecuente
          que se da en ámbitos como la escuela, el trabajo, la familia, el
          transporte público, etc.
          <br />
          Muchas víctimas del acoso sexual aceptan esta situación y no la
          denuncian porque temen perder sus trabajos y asimismo también porque
          temen no ser tomadas en serio, ya que la mayoría de las veces no
          disponen de pruebas para probar el acoso y entonces sería enfrentar un
          dicho contra otro. Si bien mayormente son mujeres las víctimas de
          acoso sexual, los hombres, también lo sufren y ha habido muchos casos
          denunciados que lo probaron. En la mayoría de las legislaciones el
          acoso de este tipo está penado por la ley y dependiendo de la gravedad
          del caso, el abusador, puede ser juzgado y condenado a prisión.
        </p>
      </div>
      <h2 class="h2-2">Esto cambia todo con "SOSCLICKMUJER".</h2>
      <div class="div-block-3">
        <div class="div-block-6">
          <div class="card-1">
            <div class="div-block-4">
              <img src="../../assets/images/icon-1_2.svg" loading="lazy" alt="" />
            </div>
            <p class="paragraph-2">
              Apoyo de especialistas a través de un Call Center de video
              llamadas.
            </p>
          </div>
          <div class="div-block-7"></div>
          <div class="card-1">
            <div class="div-block-4">
              <img src="../../assets/images/icon.svg" loading="lazy" alt="" />
            </div>
            <p class="paragraph-2">
              Ante alguna situación de acoso sexual se puede generar una
              denuncia y se activará en forma complementaria una comunicación de
              video llamada con tu red de seguridad personal.
            </p>
          </div>
          <div class="div-block-7"></div>
          <div class="card-1">
            <div class="div-block-4">
              <img src="../../assets/images/Grupo-38.svg" loading="lazy" alt="" />
            </div>
            <p class="paragraph-2">
              Esto es una Aplicación Móvil totalmente gratuita y pensada para
              ti.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="section _2">
      <div class="container">
        <div class="divider"></div>
        <div class="home-content-wrap">
          <div class="w-layout-grid about-grid">
            <div id="w-node-_86e64837-0616-515b-4568-76c147234d34-484435be">
              <div class="home-section-wrap">
                <h2 class="section-heading">
                  Una opción para ayudar a evitar el Acoso Sexual y la Violencia
                  Género
                </h2>
                <p class="paragraph-light" style="color: #000000">
                  La Violencia Intrafamiliar, es todo maltrato que afecte la
                  vida o la integridad física o psíquica de quien tenga o haya
                  tenido la calidad de cónyuge del ofensor o una relación de
                  convivencia con él; o sea, pariente por consanguinidad o por
                  afinidad en toda la línea recta o en la colateral hasta el
                  tercer grado inclusive del ofensor, su cónyuge o su actual
                  conviviente, o bien, cuando esta conducta ocurre entre los
                  padres de un hijo común, o sobre un menor de edad, adulto
                  mayor o discapacitado que se encuentre bajo el cuidado o
                  dependencia de cualquiera de los integrantes del grupo
                  familiar.<br /><br />

                  La violencia hacia la mujer y los casos de femicidio son una
                  tendencia que no solo se observa en Chile, sino que en todo el
                  mundo. Esto plantea la hipótesis de que los casos de Violencia
                  Intrafamiliar contra la mujer y los femicidios están
                  causalmente vinculados.<br /><br />Por eso diseñamos una App
                  que sea una herramienta para ayudar a evitar tanto el Acoso
                  Sexual como la Violencia Género.
                </p>
              </div>
              <a href="/about" class="button w-inline-block">
                <div>DESCARGAR APP</div>
              </a>
            </div>
            <img
              src="../../assets/images/image-1.png"
              id="w-node-_86e64837-0616-515b-4568-76c147234d3f-484435be"
              srcset="../../assets/images/image-1.png 500w, ../../assets/images/image-1.png 800w"
              sizes="(max-width: 479px) 100vw, (max-width: 991px) 80vw, 830px"
              alt=""
              class="image"
            />
          </div>
          <div class="w-layout-grid about-grid cc-about-2">
            <div id="w-node-_86e64837-0616-515b-4568-76c147234d41-484435be">
              <div class="home-section-wrap">
                <h2 class="section-heading">Una App diseñada para ti</h2>
                <p class="paragraph-light" style="color: #000000">
                  El sistema “SOSCLICK MUJER”, es una Aplicación Móvil destinada
                  para mujeres que están siendo víctimas de Acoso Sexual y de
                  VIF, las cuales son monitoreadas a través de una plataforma
                  digital online que permite una visualización en tiempo real y
                  geolocalizada cuando una mujer anónima emite la denuncia de
                  Acoso Sexual o de VIF.<br /><br />

                  Esta Aplicación Móvil se descarga en cualquier smartphone,
                  permitiendo a las mujeres violentadas acceder a dos
                  alternativas de comunicación: La primera es poder comunicarse
                  por Video Conferencia con un Call Center de Atención
                  Psicológica que ellas requieran y la segunda alternativa de
                  comunicación, es la activación de una Alerta de Acoso Sexual o
                  VIF. En forma adicional esta Aplicación Móvil posibilita que
                  la usuaria pueda invitar a familiares, amigas, vecinos, etc, a
                  ser parte de su Red personal de Seguridad.
                </p>
              </div>
              <a href="team.html" class="button w-inline-block">
                <div>DESCARGAR APP</div>
              </a>
            </div>
            <img
              src="../../assets/images/image-2.png"
              id="w-node-_86e64837-0616-515b-4568-76c147234d4c-484435be"
              srcset="../../assets/images/image-2.png 500w, ../../assets/images/image-2.png 800w"
              sizes="(max-width: 479px) 100vw, (max-width: 991px) 80vw, 830px"
              alt=""
              class="image-2"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="section-3 wf-section">
      <div class="div-block-8">
        <img
          src="../../assets/images/video.png"
          loading="lazy"
          id="w-node-_46c706eb-84dc-89a3-3ae1-502d36adcec0-484435be"
          sizes="(max-width: 479px) 100vw, (max-width: 767px) 74vw, (max-width: 991px) 80vw, (max-width: 2928px) 42vw, 1230px"
          srcset="
            ../../assets/images/video.png  500w,
            ../../assets/images/video.png  800w,
            ../../assets/images/video.png 1080w
          "
          alt=""
          class="image-5"
           @click="changeVideo"
              style="cursor: pointer"
            
        /><img
          src="../../assets/images/LP---Image-Placeholder---5.png"
          loading="lazy"
          id="w-node-a0751373-8f07-eee4-d025-2d71a4fdb6eb-484435be"
          sizes="(max-width: 479px) 100vw, (max-width: 767px) 74vw, (max-width: 991px) 75vw, 21vw"
          srcset="../../assets/images/LP---Image-Placeholder---5.png 500w"
          alt=""
        />
        <div
          id="w-node-f7a54c11-ed18-c950-b7b9-81a3fadfbf34-484435be"
          class="div-block-9"
        >
          <h3 class="heading-3">Call Center</h3>
          <p class="paragraph-3" style="text-align: justify">
            Contacto inmediato con especialista desde la Aplicación Móvil con
            tan solo tocar un botón. Tal como se mencionaba, la Aplicación Móvil
            "SOSCLICKMUJER" posibilita que la mujer agredida, pueda acceder a
            tener ayuda psicológica a través de una video conferencia con un
            profesional del área, el cual podrá atenderle en forma inmediata y a
            una sola pulsación en la alternativa de llamada “Call Center” que
            sale en la referida Aplicación Móvil.
          </p>
        </div>
      </div>
      <div class="grid-2">
        <img
          src="../../assets/images/LP---Image-Placeholder---6.png"
          loading="lazy"
          width="324"
          id="w-node-e6de10a3-91e8-6835-c394-dc3420287a37-484435be"
          srcset="../../assets/images/LP---Image-Placeholder---6.png 500w"
          sizes="(max-width: 479px) 100vw, (max-width: 767px) 74vw, (max-width: 991px) 80vw, 24vw"
          alt=""
          class="image-3"
        /><img
          src="../../assets/images/LP---Image-Placeholder---7.png"
          loading="lazy"
          width="329"
          id="w-node-e6de10a3-91e8-6835-c394-dc3420287a38-484435be"
          srcset="../../assets/images/LP---Image-Placeholder---7.png 500w"
          sizes="(max-width: 479px) 100vw, (max-width: 767px) 74vw, (max-width: 991px) 80vw, 24vw"
          alt=""
          class="image-4"
        />
        <div
          id="w-node-e6de10a3-91e8-6835-c394-dc3420287a39-484435be"
          class="div-block-9"
        >
          <h3 class="heading-2">Herramientas para superar la Situación</h3>
          <p class="paragraph-3" style="text-align: justify">
            "SOSCLICKMUJER" te posibilitará acceder a herramientas de
            comunicación de última generación, que te permitirán recibir la
            ayuda necesaria para superar estos momentos tan delicados como lo
            son situaciones de Acoso Sexual o VIF.
          </p>
        </div>
        <div
          id="w-node-d7fcfbf5-bac7-0d4d-aacc-bd7a58c54d67-484435be"
          class="div-block-9"
        >
          <h3 class="heading-4">Apoyo Inmediato</h3>
          <p class="paragraph-3" style="text-align: justify">
            Podrás obtener apoyo inmediato de tu red de seguridad personal ante
            una situación tan delicada como es el Acoso Sexual o VIF.
          </p>
        </div>
      </div>
    </div>
    <section class="testimonial-stack wf-section">
      <img src="../../assets/images/“.svg" loading="lazy" alt="" /><img
        src="../../assets/images/stars.svg"
        loading="lazy"
        alt=""
      />
      <div class="container-2">
        <div class="testimonial-card-content">
          <p class="paragraph-4">
            Es una App genial que me ayudo en esos momentos dificiles cuando
            recibí una agresión sexual.
          </p>
          <p class="paragraph-4-copy">— Marta Sepúlveda</p>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container">
        <div class="footer-wrap">
          <div>
            <img
              src="../../assets/images/logo-claro.svg"
              width="108"
              alt=""
              class="logo-image"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getPaymentValue,
  startPaymentPlan,
  getOrderStatusByToken,
} from "../helpers/api/planes";
export default {
  name: "Home",
  components: {},
  data: () => ({
    toPayPlan: {
      plan_id: 1,
      months_paid: 6,
    },
    loading: false,
    requestedStatus: false,
    playing: false,
    video: false,
  }),
  mounted() {
    if (!this.requestedStatus && this.$route.query && this.$route.query.token) {
      this.getOrderStatus(this.$route.query.token);
    }
    // setTimeout(() => {
    //   this.togglePlay();
    // }, 1000);
  },
  methods: {
    changeDrawer() {
      // changeDrawer
      this.$store.commit("setChangeDrawer");
    },
    changeVideo() {
      this.video = !this.video;
    },
    async startPayment() {
      const planPayment = await startPaymentPlan(this.toPayPlan);

      if (planPayment.code === 200) {
        if (
          planPayment.flowData &&
          planPayment.flowData.url &&
          planPayment.flowData.token
        ) {
          const payUrl =
            planPayment.flowData.url + "?token=" + planPayment.flowData.token;
          window.location.href = payUrl;
        }
      }
    },
    async getOrderStatus(order_token) {
      this.requestedStatus = true;

      this.loading = true;
      const status = await getOrderStatusByToken({ order_token });
      if (status.code === 200 && status.orderStatus) {
        console.log(status.orderStatus);
        const data = {
          flowOrder: status.orderStatus.flowOrder,
          amount: status.orderStatus.amount,
          status: status.orderStatus.status,
          forma: status.orderStatus.paymentData
            ? status.orderStatus.paymentData.media
            : "",
          fecha: status.orderStatus.paymentData
            ? status.orderStatus.paymentData.date
            : "",
        };
        this.$store.commit("setModalsStates", {
          paymentStatus: { open: true, data },
        });
      }
    },
  },
};
</script>

<style>
@import "../../assets/css/normalize.css";
@import "../../assets/css/webflow.css";
@import "../../assets/css/sosclick-mujer.webflow.css";

[data-wf-bgvideo-fallback-img] {
  display: none;
}

.theme--light.v-application {
  background: transparent !important;
}

@media (prefers-reduced-motion: reduce) {
  [data-wf-bgvideo-fallback-img] {
    position: absolute;
    z-index: -100;
    display: inline-block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.v-application a {
  color: white !important;
}

.v-application a:hover {
  color: #b73d9b !important;
}

.frame {
  /* height: 100vh !important;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: rgba(0, 0, 0, 0.959);
  width: 100%; */
  /* z-index: 10000 !important; */
}


</style>