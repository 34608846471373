import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home/index.vue'
// import PreguntasFrecuentes from '../components/PreguntasFrecuentes/index.vue'
// import Checkout from '@/components/checkout/index.vue'
import About from '../components/about/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  }

  // {
  //   path: '/preguntas_frecuentes',
  //   name: 'PreguntasFrecuentes',
  //   component: PreguntasFrecuentes
  // },
  // {
  //   path: '/Checkout',
  //   name: 'Checkout',
  //   component: Checkout
  // }

]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes
})

export default router
