
<template>
  <div>
    <div
      data-collapse="medium"
      data-animation="default"
      data-duration="400"
      data-easing="ease"
      data-easing2="ease"
      role="banner"
      class="navigation w-nav"
    >
      <div class="navigation-wrap">
        <a href="/" aria-current="page" class="logo-link w-nav-brand w--current"
          ><img src="../../assets/images/logo.svg" width="108" alt="" class="logo-image"
        /></a>
        <div class="menu">
          <nav role="navigation" class="navigation-items w-nav-menu">
            <a
              href="/"
              class="navigation-item  w-nav-link"
              style="color: #000000 !important"
              >Volver</a
            >
            <a
              href="#"
              class="navigation-item w-nav-link"
              style="color: #000000 !important"
              @click="changeVideo"
              >Video Explicativo</a
            >
            <a
                @click="changeDrawer"
              class="navigation-item w-nav-link"
              style="color: #000000 !important"
              >Contacto</a
            >
          </nav>
          <div class="menu-button w-nav-button">
            <img
              src="../../assets/images/menu-icon_1menu-icon.png"
              width="22"
              alt=""
              class="menu-icon"
            />
          </div>
        </div>
        <a
          href="mailto:mail@business.com?subject=You&#x27;ve%20got%20mail!"
          class="button cc-contact-us w-inline-block"
        >
          <div>Descargar el app</div>
        </a>
      </div>
    </div>
    <div class="section-4 wf-section"></div>
    <div class="section cc-home-wrap">
      <div class="intro-header cc-subpage">
        <div class="intro-content">
          <div data-w-id="764bac42-7142-2752-2cf3-6e8cea244d7e"  class="heading-jumbo">SOSCLICK MUJER<br></div>
          <div data-w-id="764bac42-7142-2752-2cf3-6e8cea244d81"  class="heading-jumbo-small">Atención y Contactabilidad Remota de Mujeres Violentadas y Acosadas Sexualmente.</div>
          <p data-w-id="764bac42-7142-2752-2cf3-6e8cea244d83"  class="paragraph-light justificar">El sistema <strong>“SOSCLICK MUJER”,</strong> es una Aplicación Móvil destinada 
            para mujeres que están siendo víctimas de Acoso Sexual y de VIF, las cuales son monitoreadas a través de una plataforma digital online que permite una visualización 
            en tiempo real y geolocalizada cuando una mujer emite la denuncia de Acoso Sexual o de VIF, esta denuncia es realizada de forma anónima.</p>
        </div>
      </div>
    </div>
    <section class="hero-heading-center wf-section">
      <div class="container-3 w-container">
        <div class="w-layout-grid-about grid-3-copy">
          <div id="w-node-_29e76a45-610a-0030-7368-178f9ffa0ef8-484435be" data-w-id="29e76a45-610a-0030-7368-178f9ffa0ef8"  class="heading-jumbo-small-copy">Generando una Denuncia de Acoso Sexual o VIF</div>
          <div id="w-node-_29e76a45-610a-0030-7368-178f9ffa0efa-484435be" class="div-block-10">
            <p data-w-id="29e76a45-610a-0030-7368-178f9ffa0efb"  class="paragraph-light"><strong>“SOSCLICK MUJER”,</strong> se descarga en cualquier Smartphone, permitiendo a las mujeres violentadas acceder a 
              dos alternativas de comunicación: La primera es poder comunicarse por Video Conferencia con un Call 
              Center de Atención Psicológica que ellas requieran y la segunda alternativa de comunicación, es la activación 
              de una Alerta de Acoso Sexual o VIF. </p>
          </div>
          <div id="w-node-_29e76a45-610a-0030-7368-178f9ffa0f02-484435be" data-w-id="29e76a45-610a-0030-7368-178f9ffa0f02"  class="div-block-12"></div>
          <div id="w-node-_29e76a45-610a-0030-7368-178f9ffa0f03-484435be" data-w-id="29e76a45-610a-0030-7368-178f9ffa0f03"  class="heading-jumbo-small-copy">Conformación de la Red de Seguridad Personal</div>
          <div id="w-node-_29e76a45-610a-0030-7368-178f9ffa0f05-484435be" class="div-block-11">
            <p data-w-id="29e76a45-610a-0030-7368-178f9ffa0f06"  class="paragraph-light-dark">En forma adicional, <strong>“SOSCLICK MUJER”</strong> posibilita que la usuaria pueda invitar a familiares, amigas, 
              vecinos, etc, a ser parte de su Red personal de Seguridad.</p>
          </div>
          <div id="w-node-_29e76a45-610a-0030-7368-178f9ffa0f0d-484435be" class="div-block-13">
            <div id="w-node-_29e76a45-610a-0030-7368-178f9ffa0f0e-484435be" data-w-id="29e76a45-610a-0030-7368-178f9ffa0f0e"  class="heading-jumbo-small">Registro de Alarmas Generadas</div>
          </div>
          <div id="w-node-_29e76a45-610a-0030-7368-178f9ffa0f10-484435be" class="div-block-11">
            <p data-w-id="29e76a45-610a-0030-7368-178f9ffa0f11"  class="paragraph-light-dark">En la configuración y propiedades de la aplicación <strong>“SOSCLICKMUJER”,</strong> existen acápites para que la 
              persona pueda ajustar y personalizar la aplicación, así como también, para la revisión de datos estadísticos, se va generando un historial de las denuncias realizadas, las cuales consideran 
              todo acceso al detalle de la denuncia, ubicación donde fue realizada, registro audiovisual y el estado en que se encuentra respecto del ingreso al historial de la afectada. Esto considera un 
              importante apoyo gráfico y temporal, que permitirá justificar y respaldar las futuras acciones que la afectada requiera realizar.</p>
          </div>
          <div id="w-node-_29e76a45-610a-0030-7368-178f9ffa0f16-484435be" data-w-id="29e76a45-610a-0030-7368-178f9ffa0f16"  class="div-block-12-copy-copy"></div>
        </div>
      </div>
      <div class="intro-header cc-subpage">
        <div class="intro-content">
          <div class="heading-jumbo-small">Descripción de la Aplicación Móvil de Atención Remota por Video Conferencia para Mujeres Violentadas o de Acoso Sexual</div>
          <p class="paragraph-light">La usuaria en el momento de registrase en la Aplicación Móvil <strong>“SOSCLICKMUJER”</strong>, deberá ingresar sus datos básicos tales como: Nombre, Correo Electrónico, 
            Documento de Identificación, Fecha de Nacimiento, Número de Teléfono Móvil, domicilio particular, detalle o número de medida cautelar si lo tuviera, etc.<br>‍<br>Una vez que haya ingresado estos datos, 
            deberá ingresar su información referente a la violencia intrafamiliar que éste sufriendo en un formulario tipo “<strong>FICHA PERSONAL</strong>” siempre y cuando sea una Mujer Violentada o VIF.
             Para el caso de utilizar la Aplicación ante cualquier posibilidad de Acoso Sexual no es necesario lo anteriormente expuesto.</p>
        </div>
      </div>
    </section>
    <div class="section-5 wf-section"></div>
    <section class="hero-heading-center wf-section">
      <div class="container-3 w-container">
        <div class="w-layout-grid-about grid-3">
          <div id="w-node-_3610dff9-a965-2e42-8bae-c360d6bdf721-484435be" data-w-id="3610dff9-a965-2e42-8bae-c360d6bdf721"  class="heading-jumbo-small-copy">Este Dispositivo, permite</div>
          <div id="w-node-_3610dff9-a965-2e42-8bae-c360d6bdf723-484435be" class="div-block-10">
            <p data-w-id="3610dff9-a965-2e42-8bae-c360d6bdf724"  class="paragraph-light">Seguimiento en tiempo real de la Mujer Violentada o Acosada.<br>‍<br>Reproducción de su ruta histórica de desplazamiento, 
              siempre y cuando salga de su domicilio. Para esto tiene que activar el tracking del dispositivo. Geolocalización. Botón de Call Center en la Aplicación Móvil para solicitar Atención de un Profesional 
               a través de un Sistema de Video Conferencia.<br><br>Botón de Denuncia de Acoso Sexual o VIF el cual al apretarlo generará una notificación de ayuda tanto a la central o 
              Panel WEB de Monitoreo permanente y a las personas pertenecientes a su Red Personal de Emergencia. Al mismo tiempo el smartphone empezará a grabar un Video del acosador o atacante 
              el cual servirá de prueba ante los Tribunales de Justicia.</p>
          </div>
          <div id="w-node-_3610dff9-a965-2e42-8bae-c360d6bdf72d-484435be" data-w-id="3610dff9-a965-2e42-8bae-c360d6bdf72d"  class="div-block-12-copy"></div>
          <div id="w-node-_3610dff9-a965-2e42-8bae-c360d6bdf72e-484435be" class="div-block-11"></div>
          <div id="w-node-_3610dff9-a965-2e42-8bae-c360d6bdf72f-484435be" class="div-block-13-copy">
            <div id="w-node-_3610dff9-a965-2e42-8bae-c360d6bdf730-484435be" data-w-id="3610dff9-a965-2e42-8bae-c360d6bdf730"  class="heading-jumbo-small">Se muestran, a continuación, algunas vistas de 
              Pantallas de la Aplicación Móvil:</div>
          </div>
          <div id="w-node-_3610dff9-a965-2e42-8bae-c360d6bdf732-484435be" class="div-block-11-copy"></div>
          <div id="w-node-_3610dff9-a965-2e42-8bae-c360d6bdf733-484435be" class="div-block-15">
            <p data-w-id="3610dff9-a965-2e42-8bae-c360d6bdf734"  class="paragraph-light-dark">Las cinco pantallas anteriores muestran el inicio de Aplicación Móvil, Comunicación por Video Conferencia con el Call Center 
              de Atención Profesional; la Red de Seguridad Personal que puede tener la usuaria (mujer violentada o acosada en este caso), como así también, la generación de una comunicación múltiple de video conferencia 
              ante la generación de una Alarma por parte de la Mujer Violentada o Acosada y el detalle tanto de la geolocalización de ésta, en su smartphone personal, como así también, en el Panel WEB de 
              comunicación total.</p>
          </div>
        </div>
      </div>
      <div class="intro-header cc-subpage">
        <div class="intro-content">
          <div data-w-id="3610dff9-a965-2e42-8bae-c360d6bdf738"  class="heading-jumbo-small">Descripción del problema o brecha específica a abordar</div>
          <p data-w-id="3610dff9-a965-2e42-8bae-c360d6bdf73a"  class="paragraph-light">La presente propuesta busca contribuir en el ámbito de la seguridad personal, poniendo énfasis en la protección de género, 
            mediante una plataforma de monitoreo, registro y acción rápida, en la medida que se desarrollen casos de violencia intrafamiliar o acos0 sexual.
            <br>‍<br>Con el desarrollo e implementación de esta herramienta se busca destrabar la problemática de la invisibilidad de los hechos que suceden de 
            manera espontánea en favor del resguardo de las personas, mediante mecanismos de protección con redes de apoyo y posterior contención de casos existentes.</p>
        </div>
      </div>
    </section>
    <section class="hero-heading-left wf-section">
      <div class="container-2">
        <div class="hero-wrapper">
          <div class="hero-split">
            <h1 data-w-id="250c6277-a29d-8eb5-a5c1-fc58c4806891"  class="heading-5">Panel WEB de Denuncias.</h1>
            <p data-w-id="250c6277-a29d-8eb5-a5c1-fc58c4806893"  class="margin-bottom-24px-2 justificar">A continuación, se muestran algunas de las pantallas más relevantes del 
              Sistema o Panel WEB de comunicación total, al cual tendrán acceso el Personal de Seguridad que así se determine.</p>
            <h1 data-w-id="250c6277-a29d-8eb5-a5c1-fc58c4806895"  class="heading-5">Panel WEB Principal</h1>
            <p data-w-id="250c6277-a29d-8eb5-a5c1-fc58c4806897"  class="margin-bottom-24px-2 justificar">Ubicación de cada Mujer Violentada o Acosada en el Mapa del Panel WEB: Permite visualizar 
              la posición de toda Mujer Violentada o Acosada, su Georrefereciamiento y las que están en lista de espera o que hayan generado una Alarma.
              <br><br>Icono desplegable de Alertas con prioridad de Atención: Listado de alertas sin atender generadas por las Mujeres Violentadas.</p>
          </div>
          <div class="hero-split _2"><img src="../../assets/images/01_iMac-M1-Mockups.png" loading="lazy"  data-w-id="250c6277-a29d-8eb5-a5c1-fc58c480689d" sizes="(max-width: 479px) 94vw, (max-width: 767px) 81vw, (max-width: 991px) 55vw, 56vw" srcset="images/01_iMac-M1-Mockups.png 500w, images/01_iMac-M1-Mockups.png 781w" alt="" class="shadow-two"></div>
        </div>
        <h1 data-w-id="250c6277-a29d-8eb5-a5c1-fc58c480689e"  class="heading-5  justificar">En el detalle de la denuncia podrás ver el registro audiovisual, 
          la ubicación donde se generó la alerta, gestionar el estado de la denuncia como también la información de contacto de la persona afectada.</h1>
        <div data-w-id="250c6277-a29d-8eb5-a5c1-fc58c48068a0"  class="div-block-16"></div>
      </div>
    </section>

    <div class="section cc-store-home-wrap">


      <div
        style="
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 99999 !important;
        "
        v-if="video"
        @click="changeVideo"
      >
        <div
          class="w-lightbox-backdrop"
          style="transition: opacity 300ms ease 0s; opacity: 1"
        >
          <div class="w-lightbox-container">
            <div class="w-lightbox-content">
              <div
                class="w-lightbox-view"
                tabindex="0"
                id="w-lightbox-view"
                style="opacity: 1"
              >
                <div class="w-lightbox-frame">
                  <figure class="w-lightbox-figure">
                    <v-progress-circular
                      :size="50"
                      :width="7"
                      color="white"
                      indeterminate
                      style="
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                      "
                    ></v-progress-circular>
                    <img
                      class="w-lightbox-img w-lightbox-image"
                      src="data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20width=%22940%22%20height=%22528%22/%3E"
                      alt=""
                    />
                    <iframe
                      class="embedly-embed w-lightbox-embed"
                      src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FtxjGqv-GUxg%3Ffeature%3Doembed&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DtxjGqv-GUxg%26rel%3D0%26enablejsapi%3D1&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FRW_EclTXHxk%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube"
                      width="940"
                      height="528"
                      scrolling="no"
                      title="YouTube embed"
                      frameborder="0"
                      allow="autoplay; fullscreen"
                      allowfullscreen="true"
                    >
                    </iframe>
                  </figure>
                </div>
              </div>
              <div
                class="w-lightbox-spinner w-lightbox-hide"
                role="progressbar"
                aria-live="polite"
                aria-hidden="true"
                aria-busy="false"
                aria-valuemin="0"
                aria-valuemax="100"
                aria-valuenow="100"
                aria-valuetext="Loaded image"
              ></div>
              <div
                class="w-lightbox-control w-lightbox-left w-lightbox-inactive"
                role="button"
                aria-hidden="true"
                aria-controls="w-lightbox-view"
                aria-label="previous image"
                tabindex="-1"
              ></div>
              <div
                class="w-lightbox-control w-lightbox-right w-lightbox-inactive"
                role="button"
                aria-hidden="true"
                aria-controls="w-lightbox-view"
                aria-label="next image"
                tabindex="-1"
              ></div>
              <div
                class="w-lightbox-control w-lightbox-close"
                role="button"
                aria-label="close lightbox"
                tabindex="0"
                @click="changeVideo"
              ></div>
            </div>
            <div class="w-lightbox-strip" role="tablist"></div>
          </div>
        </div>
      </div>

    </div>

    <div class="section">
      <div class="container">
        <div class="footer-wrap">
          <div>
            <img
              @click="goToHome"
              src="../../assets/images/logo-claro.svg"
              width="108"
              alt=""
              class="logo-image"
              style="cursor: pointer"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getOrderStatusByToken, startPaymentPlan,} from "../helpers/api/planes";

export default {
  name: "About",
  components: {},
  data: () => ({
    toPayPlan: {
      plan_id: 1,
      months_paid: 6,
    },
    loading: false,
    requestedStatus: false,
    playing: false,
    video: false,
  }),
  mounted() {
    // if (!this.requestedStatus && this.$route.query && this.$route.query.token) {
    //   this.getOrderStatus(this.$route.query.token);
    // }
    // setTimeout(() => {
    //   this.togglePlay();
    // }, 1000);
  },
  methods: {
    changeDrawer() {
      // changeDrawer
      this.$store.commit("setChangeDrawer");
    },
    // togglePlay() {
    //   this.playing = !this.playing;
    //   if (this.playing) {
    //     this.$refs.videoRef.play();
    //   } else {
    //     this.$refs.videoRef.pause();
    //   }
    // },
    goToHome() {
      this.$router.push({ name: "Home" });
    },
    changeVideo() {
      this.video = !this.video;
    },
    async startPayment() {
      const planPayment = await startPaymentPlan(this.toPayPlan);

      if (planPayment.code === 200) {
        if (
          planPayment.flowData &&
          planPayment.flowData.url &&
          planPayment.flowData.token
        ) {
          window.location.href = planPayment.flowData.url + "?token=" + planPayment.flowData.token;
        }
      }
    },
    async getOrderStatus(order_token) {
      this.requestedStatus = true;

      this.loading = true;
      const status = await getOrderStatusByToken({ order_token });
      if (status.code === 200 && status.orderStatus) {
        console.log(status.orderStatus);
        const data = {
          flowOrder: status.orderStatus.flowOrder,
          amount: status.orderStatus.amount,
          status: status.orderStatus.status,
          forma: status.orderStatus.paymentData
            ? status.orderStatus.paymentData.media
            : "",
          fecha: status.orderStatus.paymentData
            ? status.orderStatus.paymentData.date
            : "",
        };
        this.$store.commit("setModalsStates", {
          paymentStatus: { open: true, data },
        });
      }
    },
  },
};
</script>

<style>
/*!* @import "../../assets/css/normalize.css";*/
/*@import "../../assets/css/webflow.css";*/

.justificar {
  text-align: justify;
}

[data-wf-bgvideo-fallback-img] {
  display: none;
}

.theme--light.v-application {
  background: transparent !important;
}

@media (prefers-reduced-motion: reduce) {
  [data-wf-bgvideo-fallback-img] {
    position: absolute;
    z-index: -100;
    display: inline-block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.v-application a {
  color: white !important;
}

.v-application a:hover {
  color: #b73d9b !important;
}

.w-layout-grid-about {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}


.section {
  margin-right: 0px;
  margin-left: 0px;
  background-color: #000;
}

.section.cc-cta {
  padding-right: 80px;
  padding-left: 80px;
  background-color: #f4f4f4;
}

.section.cc-home-wrap {
  background-color: #fff;
}

.section.cc-store-home-wrap {
  margin-right: 0px;
  margin-left: 0px;
}

.section._2 {
  background-color: #fff;
}

.container {
  width: 100%;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
}

.button {
  padding: 12px 25px;
  border-radius: 0px;
  background-color: #1a1b1f;
  -webkit-transition: background-color 400ms ease, opacity 400ms ease, color 400ms ease;
  transition: background-color 400ms ease, opacity 400ms ease, color 400ms ease;
  color: #fff;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
}

.button:hover {
  background-color: #32343a;
  color: #fff;
}

.button:active {
  background-color: #43464d;
}

.button.cc-jumbo-button {
  padding: 16px 35px;
  font-size: 14px;
  line-height: 26px;
}

.button.cc-jumbo-button.cc-jumbo-white {
  background-color: #b73d9b;
  color: #000;
}

.button.cc-jumbo-button.cc-jumbo-white:hover {
  background-color: #f1f1f1;
}

.button.cc-jumbo-button.cc-jumbo-white:active {
  background-color: #e1e1e1;
}

.button.cc-contact-us {
  position: relative;
  z-index: 5;
}

.button.cc-white-button {
  padding: 16px 35px;
  background-color: #fff;
  color: #202020;
  font-size: 14px;
  line-height: 26px;
}

.button.cc-white-button:hover {
  background-color: hsla(0, 0%, 100%, 0.8);
}

.button.cc-white-button:active {
  background-color: hsla(0, 0%, 100%, 0.9);
}


.rich-text p {
  margin-top: 15px;
  margin-bottom: 25px;
  opacity: 0.6;
}

.paragraph-light {
  margin-bottom: 0px;
  opacity: 1;
  color: #fff;
  font-size: 14px;
  line-height: 24px;
  text-align: justify;
}

.paragraph-light._2 {
  color: #000;
}

.heading-jumbo {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 64px;
  line-height: 80px;
  font-weight: 900;
  text-align: left;
  text-transform: none;
}

.heading-jumbo-small {
  margin: 10px 10px 15px 0px;
  color: #fff;
  font-size: 32px;
  line-height: 50px;
  font-weight: 400;
  text-align: left;
  text-transform: none;
}

.logo-link {
  z-index: 1;
}

.logo-link:hover {
  opacity: 0.8;
}

.logo-link:active {
  opacity: 0.7;
}

.menu {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.navigation-wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.navigation {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 30px 50px 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
}

.navigation-item {
  padding-top: 9px;
  padding-bottom: 9px;
  opacity: 0.6;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.navigation-item:hover {
  opacity: 0.9;
}

.navigation-item:active {
  opacity: 0.8;
}

.navigation-item.w--current {
  opacity: 1;
  color: #1a1b1f;
  font-weight: 600;
}

.navigation-item.w--current:hover {
  opacity: 0.8;
  color: #32343a;
}

.navigation-item.w--current:active {
  opacity: 0.7;
  color: #32343a;
}

.logo-image {
  display: block;
}

.navigation-items {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.footer-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 70px;
  padding-bottom: 70px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.intro-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 620px;
  margin-bottom: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f4f4f4;
  background-image: url('../../assets/images/HERO-BANNER.png');
  background-position: 0px 0px;
  background-size: cover;
  color: #fff;
}

.intro-header.cc-subpage {
  height: 480px;
  margin-bottom: 0px;
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #b73d9b;
  background-image: none;
  color: #fff;
}

.intro-content {
  width: 60%;
  max-width: 1140px;
  padding-right: 0px;
  padding-left: 0px;
  text-align: center;
}

.intro-content.cc-homepage {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: left;
}

.container-2 {
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
}


.hero-heading-center {
  position: relative;
  padding: 80px 0px 0px;
  border: 1px none #000;
  background-color: #fff0fc;
}


.hero-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.hero-split {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 40%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.hero-split._2 {
  max-width: 60%;
}



.shadow-two {
  box-shadow: 0 4px 24px 0 rgba(150, 163, 181, 0.08);
}

.grid-3 {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-rows: auto auto auto auto auto auto;
  grid-template-rows: auto auto auto auto auto auto;
  background-color: #fff;
}

.div-block-10 {
  padding: 30px 30px 20px;
  background-color: #b73d9b;
}

.heading-jumbo-small-copy {
  margin: 0px;
  padding: 30px;
  color: #b73d9b;
  font-size: 32px;
  line-height: 50px;
  font-weight: 400;
  text-transform: none;
}

.paragraph-light-dark {
  margin-bottom: 0px;
  opacity: 1;
  color: #000;
  font-size: 14px;
  line-height: 24px;
  text-align: justify;
}

.div-block-11 {
  padding: 30px;
}

.div-block-12 {
  width: 100%;
  height: 100%;
  background-image: url('../../assets/images/LP---Image-Placeholder---7.png');
  background-position: 0% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.div-block-13 {
  padding-left: 20px;
  background-color: #b73d9b;
  color: #fff;
}

.div-block-12-copy {
  width: 100%;
  height: 100%;
  background-image: url('../../assets/images/image-2.png');
  background-position: 50% 50%;
  background-size: cover;
}

.container-3 {
  margin-bottom: 60px;
}

.section-4 {
  height: 60vh;
  background-image: url('../../assets/images/hand-isolated--iPhone-gestures-01-min.png');
  background-position: 50% 40%;
  background-size: cover;
  background-attachment: fixed;
}

.section-5 {
  height: 40vh;
  background-image: url('../../assets/images/2-min.png');
  background-position: 0% 0%;
  background-size: auto;
  background-repeat: repeat-x;
}


.div-block-12-copy {
  width: 100%;
  height: 100%;
  background-image: url('../../assets/images/3-in-1-Phone-Mockup.png');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.div-block-12-copy-copy {
  width: 100%;
  height: 100%;
  background-image: url('../../assets/images/image-2.png');
  background-position: 50% 50%;
  background-size: cover;
}

.div-block-11-copy {
  height: 450px;
  padding: 30px;
  background-image: url('../../assets/images/6.png');
  background-position: 50% 50%;
  background-size: cover;
}

.div-block-13-copy {
  padding-left: 20px;
  background-color: #edcbe6;
  color: #b73d9b;
}

.grid-3-copy {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-rows: auto auto auto auto auto;
  grid-template-rows: auto auto auto auto auto;
  background-color: #fff;
}

.div-block-15 {
  padding: 30px;
}

.hero-heading-left {
  position: relative;
  padding: 80px 30px;
  border-bottom: 1px solid #e4ebf3;
  background-color: #fef4ff;
  background-image: linear-gradient(105deg, #fff, #fbd4ff);
}

.margin-bottom-24px-2 {
  margin-bottom: 24px;
  color: #000;
  font-size: 14px;
}

.heading-5 {
  font-size: 24px;
  line-height: 32px;
}

.div-block-16 {
  height: 750px;
  background-image: url('../../assets/images/05_iMac-M1-Mockups.png');
  background-position: 50% 50%;
  background-size: auto;
}

@media screen and (max-width: 991px) {
  .section.cc-cta {
    padding-right: 0px;
    padding-left: 0px;
  }


  .container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .button {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .button.cc-contact-us {
    display: none;
  }

  .heading-jumbo {
    font-size: 56px;
    line-height: 70px;
  }

  .logo-link.w--current {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .menu-icon {
    display: block;
  }

  .menu {
    position: static;
    margin-left: 30px;
  }

  .navigation-wrap {
    background-color: #fff;
  }

  .navigation {
    padding: 25px 30px;
  }

  .navigation-item {
    padding: 15px 30px;
    -webkit-transition: background-color 400ms ease, opacity 400ms ease, color 400ms ease;
    transition: background-color 400ms ease, opacity 400ms ease, color 400ms ease;
    text-align: center;
  }

  .navigation-item:hover {
    background-color: #f7f8f9;
  }

  .navigation-item:active {
    background-color: #eef0f3;
  }

  .menu-button {
    padding: 0px;
  }

  .menu-button.w--open {
    background-color: transparent;
  }

  .navigation-items {
    background-color: #fff;
  }

  .intro-header.cc-subpage {
    height: 600px;
  }

  .intro-content {
    width: 90%;
  }

  .container-2 {
    max-width: 728px;
  }

  .hero-wrapper {
    margin-bottom: -40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .hero-split {
    max-width: 100%;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) {
  .section {
    margin-right: 0px;
    margin-left: 0px;
  }

  .section.cc-cta {
    padding: 15px;
  }

  .section.cc-home-wrap {
    margin-right: 0px;
    margin-left: 0px;
  }

  .section.cc-store-home-wrap {
    margin-right: 0px;
    margin-left: 0px;
  }

  .container {
    text-align: center;
  }

  .heading-jumbo {
    font-size: 50px;
    line-height: 64px;
  }

  .heading-jumbo-small {
    font-size: 30px;
    line-height: 52px;
  }

  .logo-link {
    padding-left: 0px;
  }

  .navigation {
    padding: 20px 30px;
  }



  .intro-header.cc-subpage {
    height: 850px;
  }

  .intro-content {
    width: 80%;
  }


  .hero-heading-center {
    padding: 10px 5px 60px;
  }

  .hero-split._2 {
    max-width: 85%;
  }

  .heading-jumbo-small-copy {
    font-size: 20px;
    line-height: 36px;
  }

  .div-block-11 {
    padding-top: 10px;
  }

  .div-block-12-copy {
    background-position: 30% 10%;
    background-size: cover;
  }

  .grid-3-copy {
    -ms-grid-columns: 0.75fr 1fr;
    grid-template-columns: 0.75fr 1fr;
  }

  .hero-heading-left {
    padding: 60px 15px;
  }

  .div-block-16 {
    height: 400px;
    background-size: cover;
  }
}

@media screen and (max-width: 479px) {
  h1 {
    font-size: 36px;
    line-height: 52px;
  }

  .paragraph-light {
    font-size: 12px;
    line-height: 18px;
  }

  .heading-jumbo {
    font-size: 24px;
    line-height: 48px;
  }

  .heading-jumbo-small {
    padding-left: 0px;
    font-size: 20px;
    line-height: 26px;
  }

  .menu {
    margin-left: 15px;
  }

  .navigation {
    padding-right: 20px;
    padding-left: 20px;
  }

  .menu-button {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .menu-button.w--open {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }



  .intro-header {
    height: 800px;
    background-color: #000;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5))), url('../../assets/images/HERO-BANNER.png');
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../assets/images/HERO-BANNER.png');
    background-position: 0px 0px, 50% 50%;
    background-size: auto, contain;
    opacity: 1;
  }

  .intro-header.cc-subpage {
    height: 550px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 20px;
  }

  .intro-content {
    width: 90%;
  }

  .intro-content.cc-homepage {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .container-2 {
    max-width: none;
  }


  .hero-heading-center {
    padding-bottom: 0px;
  }

  .centered-heading {
    margin-bottom: 24px;
  }

  .hero-split._2 {
    max-width: 100%;
  }

  .grid-3 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .div-block-10 {
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .heading-jumbo-small-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .div-block-11 {
    display: none;
    overflow: visible;
    width: 100%;
    height: 390px;
    padding: 0px;
  }

  .div-block-13 {
    width: 100%;
    padding-left: 20px;
  }

  .container-3 {
    margin-bottom: 0px;
  }

  .section-4 {
    background-position: 50% 50%;
  }

  .div-block-12-copy {
    height: 450px;
    background-position: 50% 50%;
  }

  .div-block-11-copy {
    width: 100%;
    height: 350px;
    padding: 0px;
    background-size: cover;
    background-repeat: repeat;
  }

  .grid-3-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    grid-auto-flow: column;
    grid-auto-columns: 0.25fr;
    -ms-grid-columns: 1.25fr;
    grid-template-columns: 1.25fr;
  }

  .div-block-15 {
    padding-right: 20px;
    padding-left: 20px;
  }

  .heading-5 {
    font-size: 18px;
    line-height: 24px;
  }

  .div-block-16 {
    height: 250px;
  }
}

#w-node-_29e76a45-610a-0030-7368-178f9ffa0ef8-484435be {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_29e76a45-610a-0030-7368-178f9ffa0efa-484435be {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_29e76a45-610a-0030-7368-178f9ffa0f02-484435be {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 2;
  grid-row-end: 4;
}

#w-node-_29e76a45-610a-0030-7368-178f9ffa0f03-484435be {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_29e76a45-610a-0030-7368-178f9ffa0f05-484435be {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 3;
  grid-row-start: 3;
  -ms-grid-row-span: 1;
  grid-row-end: 4;
}

#w-node-_29e76a45-610a-0030-7368-178f9ffa0f0d-484435be {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 2;
  grid-column-end: 3;
  -ms-grid-row: 4;
  grid-row-start: 4;
  -ms-grid-row-span: 1;
  grid-row-end: 5;
}

#w-node-_29e76a45-610a-0030-7368-178f9ffa0f0e-484435be {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 2;
  grid-column-end: 3;
  -ms-grid-row: 4;
  grid-row-start: 4;
  -ms-grid-row-span: 1;
  grid-row-end: 5;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_29e76a45-610a-0030-7368-178f9ffa0f10-484435be {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 5;
  grid-row-start: 5;
  -ms-grid-row-span: 1;
  grid-row-end: 6;
}

#w-node-_29e76a45-610a-0030-7368-178f9ffa0f16-484435be {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 5;
  grid-row-start: 5;
  -ms-grid-row-span: 1;
  grid-row-end: 6;
}

#w-node-_3610dff9-a965-2e42-8bae-c360d6bdf721-484435be {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_3610dff9-a965-2e42-8bae-c360d6bdf723-484435be {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 2;
  grid-row-end: 4;
}

#w-node-_3610dff9-a965-2e42-8bae-c360d6bdf72d-484435be {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 3;
  grid-row-end: 4;
}

#w-node-_3610dff9-a965-2e42-8bae-c360d6bdf72e-484435be {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 3;
  grid-row-start: 3;
  -ms-grid-row-span: 1;
  grid-row-end: 4;
}

#w-node-_3610dff9-a965-2e42-8bae-c360d6bdf72f-484435be {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 2;
  grid-column-end: 3;
  -ms-grid-row: 4;
  grid-row-start: 4;
  -ms-grid-row-span: 1;
  grid-row-end: 5;
}

#w-node-_3610dff9-a965-2e42-8bae-c360d6bdf730-484435be {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 2;
  grid-column-end: 3;
  -ms-grid-row: 4;
  grid-row-start: 4;
  -ms-grid-row-span: 1;
  grid-row-end: 5;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_3610dff9-a965-2e42-8bae-c360d6bdf732-484435be {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 2;
  grid-column-end: 3;
  -ms-grid-row: 5;
  grid-row-start: 5;
  -ms-grid-row-span: 1;
  grid-row-end: 6;
}

#w-node-_3610dff9-a965-2e42-8bae-c360d6bdf733-484435be {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

@media screen and (max-width: 991px) {
}

@media screen and (max-width: 767px) {
}



</style>