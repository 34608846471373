<template>
  <div class="navbar-no-shadow wf-section">
    <!-- <div
      data-animation="default"
      data-collapse="medium"
      data-duration="400"
      data-easing="ease"
      data-easing2="ease"
      role="banner"
      class="navbar-no-shadow-containerhtml w-nav"
    >
      <div class="containerhtml-regular">
        <div class="navbar-wrapper">
          <a href="/" class="navbar-brand w-nav-brand"
            ><img src="images/logo.svg" loading="lazy" width="140" alt=""
          /></a>
          <nav role="navigation" class="nav-menu-wrapper w-nav-menu">
            <ul role="list" class="nav-menu w-list-unstyled">
              <li>
                <a
                  href="http://www.iduam.com/"
                  class="nav-link"
                  target="_self"
                  >Web Iduam</a
                >
              </li>
              <li>
                <a href="/#comofunciona" class="nav-link">Como Funciona</a>
              </li>
              <li><a href="/#Smartband" class="nav-link">Smartband</a></li>
              <li><a href="/#" class="nav-link">Monitoreo</a></li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCBnz1KlUdfLt1misGsGk_lQ"
                  class="nav-link"
                  target="_self"
                  >VideoTutorial</a
                >
              </li>
              <li>
                <a
                  href="https://ventasadultomayor.sosclick.cl/"
                  class="nav-link"
                  target="_self"
                  >Adulto Mayor</a
                >
              </li>
              <li>
                <a
                  href="http://ventasboton.sosclick.cl/"
                  class="nav-link"
                  target="_self"
                  >Botón Adulto Mayor</a
                >
              </li>
              <li>
                <a href="/preguntas_frecuentes" class="nav-link"
                  >Preguntas Frecuentes</a
                >
              </li>
              <li class="mobile-margin-top-10" v-show="isMobile">
                <a
                  href="https://www.click2call.cl/?ShareLink=5"
                  class="nav-link"
                  target="_self"
                  >Contacto</a
                >
              </li>
              <li class="mobile-margin-top-10" v-show="!isMobile">
                <div class="nav-button-wrapper">
                  <a
                    href="https://www.click2call.cl/?ShareLink=5"
                    class="button-primary w-button"
                    target="_self"
                    >Contactar</a
                  >
                </div>
              </li>
            </ul>
          </nav>
          <div class="menu-button w-nav-button">
            <div class="w-icon-nav-menu"></div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "Header",
  data: () => ({
    isMobile: false,
  }),
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 992;
    },
  },
};
</script>
<style>
</style>